<template>
    <div style="height:100%;">

        <!-- <a-breadcrumb style="margin: 6px 10px 0 ;">
            <a-button style="float:right" href="#" onclick="window.history.go(-1); return false;">返回</a-button>
        </a-breadcrumb> -->
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                <a-card style="width:100%;min-height:100%;" :bordered="false">


                    <a-form-model layout="horizontal" style="padding:10px 00px;height:auto;width:800px;" :rules="rules" ref="ruleForm" :model="MomentModel">
                        <a-form-model-item label="朋友圈标题" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Titles">
                            <a-input placeholder="请输入标题" v-model="MomentModel.Titles" onkeyup="this.value=this.value.replace(/\s+/g,'')"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈标签" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Labels">
                            <a-input placeholder="请输入标签" v-model="MomentModel.Labels" onkeyup="this.value=this.value.replace(/\s+/g,'')"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈内容" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Contents">
                            <a-input placeholder="请输入内容" type="textarea" rows="6" v-model="MomentModel.Contents" onkeyup="this.value=this.value.replace(/\s+/g,'')"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈图片" style="margin-bottom:20px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-upload name="image"
                                      :data="ParamData"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload"
                                      :fileList="MomentModel.fileList"
                                      @change="imgChange">
                                <div v-if="MomentModel.fileList.length < 1 && this.$route.query.rowType !='select'">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <a-form-item v-if="this.$route.query.rowType !='select'">
                            <a-button type="primary" class="margin-r" @click="SubmitMoent('ruleForm')" style="margin-left:14rem;">保存</a-button>
                        </a-form-item>

                    </a-form-model>

                </a-card>

            </div>
        </a-layout-content>

    </div>
</template>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.config.js"></script>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.all.js"></script>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    //import imagedata from "@/Plugin/UploadDataHelper.js";
    //var ue = UE.getEditor('edcontainer');
    export default {
        name: "MaterialDetails",
        data() {
            return {
                MomentModel: {
                    Titles: "",
                    Labels: "",
                    Contents: "",
                    fileList: []
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                MaterialDetails: [],
                headers: {
                    authorization: 'authorization-text',
                },
                rules: {
                    Titles: [{ message: "请输入朋友圈标题", required: true, trigger: "blur" }],
                    Labels: [{ message: "请输入朋友圈标签", required: true, trigger: "blur" }],
                    Contents: [{ message: "请输入朋友圈内容", required: true, trigger: "blur" }]
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                config: {
                    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                },
                rangeConfig: {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                }
            };

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'time_related_controls' });
        },
        props: {},//组件属性
        methods: {
            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.MomentModel.fileList = info.fileList;
            },
            // removeImg: function (info) {
            //    var self = this;
            //     self.MomentModel.fileList = info.fileList;
            // },
            loadData: function (id) {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/MomentDetail",
                    data: {
                        id: this.$route.query.id
                    },
                    OnSuccess: function (data) {
                        //self.MomentModel = data.data;
                        self.MomentModel.Titles = data.data.Titles;
                        self.MomentModel.Labels = data.data.Labels;
                        self.MomentModel.Contents = data.data.Contents;
                        if (data.data.TitleImg != "" && data.data.TitleImg != null) {
                            self.MomentModel.fileList = [{
                                name: data.data.TitleImg,
                                response: data.data.TitleImg,
                                status: "done",
                                uid: "0",
                                url: data.data.TitleImg
                            }];
                        }
                        else {
                            self.MomentModel.fileList = [];
                        }
                    }
                };
                http.Post(op);
            },
            SubmitMoent(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage()) {
                            var op = {
                                url: "/MarketingModule/Material/EditMoment",
                                data: {
                                    ID: this.$route.query.id,
                                    Titles: self.MomentModel.Titles,
                                    Labels: self.MomentModel.Labels,
                                    TitleImg: self.MomentModel.fileList.length > 0 ? self.MomentModel.fileList[0].response : "",
                                    Contents: self.MomentModel.Contents
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    self.$router.push({ name: 'Material_List' });
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.MomentModel.fileList != null) {
                    if (self.MomentModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传图片！");
                    }
                }
                return rt;
            }
        },
        mounted() {
            this.loadData(this.id);
        }
    }
</script>